import getConfig from '@cenume/config-center'

const API_ROUTES = {
  ARTICLES: '/articles',
  AUTH: '/auth'
}

const getEndpoint = (route: keyof typeof API_ROUTES) => 
  `${getConfig('API_URL')}${getConfig('API_PORT') ? ':' + getConfig('API_PORT') : ''}${API_ROUTES[route]}/`

export const getArticles = async ()=>{
  const res = await fetch(`${getEndpoint("ARTICLES")}`)
    .then(response => response.json())
    .then(data => {
      console.log(data)
      return data;
    });
  return res;
}