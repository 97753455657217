/**
 * Render SPA
 */

import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { Routes } from '../../constants/routes';
import { Explore } from './Explore';

export const SPARouter = ()=>{ 
  return <BrowserRouter>
    <Switch>
      <Route path={Routes.HOME}>
        <Explore/>
      </Route>
    </Switch>
  </BrowserRouter>
}
